import {
  Spacer,
  Box,
  TextProps
} from "@chakra-ui/react";
import {
  useState,
  useEffect
} from "react";
import { JSONAPIResponse } from "src/api/json-api-response";
import {
  PropertyFootprint,
  Recommendation,
  RecommendationStatus
} from "src/api/users/footprint";
import { useUsersApi } from "src/api/users/users.api";
import { Wrapper } from "src/components/shared";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import { useCurrentUserContext } from "src/hooks/useCurrentUserContext";

import { ScrollToTopButton } from "./ScrollToTopButton";
// import ChooseRecommendationsCheckboxSection from "./sections/ChooseRecommendationsCheckboxSection";
import ComingSoonSection from "./sections/ComingSoonSection";
import { MakeChangesSection } from "./sections/MakeChangesSection";
import { RecommendationSection } from "./sections/RecommendationSection";
import { RecommendationsTabularSection } from "./sections/RecommendationsTabularSection";

interface RecommendationsFootprintProps {
  getFootprintResponse: ApiResponse<JSONAPIResponse<PropertyFootprint>>,
  setRecommendationsToAction: React.Dispatch<React.SetStateAction<Recommendation[]>>
  recommendationsToAction: Recommendation[],
  isAnonUser: boolean
}

enum RecommendationView {
  TABLE,
  CARD
}

const RecommendationsFootprint: React.FC<RecommendationsFootprintProps> = ({
  getFootprintResponse, isAnonUser = false, setRecommendationsToAction, recommendationsToAction
}) => {
  const recommendationsData = getFootprintResponse.data?.data.recommendations;
  const [ recommendationView, setRecommendationView ] = useState(RecommendationView.TABLE);

  return (
    <>
      <Box w="100%">
        <Wrapper
          variant={{ base: "narrow" }}
          px={{
            base: "2rem",
            md: "0"
          }}
        >
          <MakeChangesSection />

        </Wrapper>
      </Box>

      {!!recommendationsData?.length && (
        <>

          <Box w="100%">

            {/* create a toggle that on click switches between table and card views */}

            {recommendationView === RecommendationView.TABLE && (
              <RecommendationsTabularSection
                isAnonUser={isAnonUser}
                recommendations={recommendationsData}
                impossibleCount={getFootprintResponse.data?.data.recommendationsImpossible || 0}
              />
            )}

            {/*recommendationView === RecommendationView.CARD && (
              <RecommendationSection
                recommendations={recommendationsData}
                toggleRecommendation={actionRecommendation}
                getIsToggled={getSelected}
              />
            )*/}

          </Box>

        </>
      )}

      {/*
      <Box
        w="100%"
        maxW="690px"
        mx="auto"
        textAlign="center"
      >
        {!!getFootprintResponse.data?.data.comingSoon.length && (
          <Text {...comingSoonTextStyles}>
            Coming soon
          </Text>
        )}
      </Box>

      <Spacer
        h={theme.spacing[ "12" ]}
      />
        */}

      {/* Coming soon */}

      {/*
      {!!recommendationsData?.length && (
        <Box
          px={[ ".5rem", "1rem" ]}
          w="100%"
        >
          <ComingSoonSection data={getFootprintResponse.data?.data.comingSoon} />
        </Box>
      )}*/}

      <Box w="100%">
        <Wrapper
          variant={{ base: "text" }}
          // textAlign="center"
          color={theme.colors.gray[ 700 ]}
          my={theme.spacing[ 4 ]}
          fontSize="sm"
        >
          <Text>¹ Note that the costs and savings are illustrative, based on February 2024 energy and fuel prices and homes and cars like yours, but a full survey would be needed to confirm these. The savings above are also for recommendations in isolation - combining solar panels with a heat pump and/or electric car will normally give better returns than either on its own, for instance, and this is shown in the ticker at the bottom of the page.
          </Text>

        </Wrapper>
      </Box>

      <Box w="100%">
        <Wrapper
          variant="text"
          textAlign="center"
          my={theme.spacing[ 10 ]}
        >
          <ScrollToTopButton my={theme.spacing[ 10 ]} />
        </Wrapper>
      </Box>

      {/* {getFootprintResponse && <Box>{JSON.stringify(getFootprintResponse.data?.data.recommendations.filter(recommendation => recommendation.interested === true))}</Box>} */}
    </>
  );
};

const comingSoonTextStyles: TextProps = {
  display: "inline-block",
  fontSize: {
    base: theme.fontSizes.md,
    md: theme.fontSizes.lg
  },
  fontWeight: {
    base: "semibold",
    md: "medium"
  },
  lineHeight: 1.2,
  bg: "white",
  p: ".75em 1.5em",
  borderRadius: theme.radius.rounded_full,
  mx: "auto"
};

export default RecommendationsFootprint;