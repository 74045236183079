import React, {
  Suspense,
  useEffect,
  useRef,
  useState
} from "react";
import {
  Switch,
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import ReactGA from "react-ga4";
import CookieConsent, { Cookies } from "react-cookie-consent";
import theme from "src/components/theme";
import FacebookPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";
import { hotjar } from "react-hotjar";
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import { isStage } from "src/helpers/stage";
import { useAuthContext } from "src/hooks/useAuthContext";
import { useUtmParameters } from "src/hooks/useUtmParameters";
import NextSteps from "src/components/footprint/NextSteps";
import SurveyPurchaseForm from "src/components/footprint/SurveyPurchaseForm";
import { SurveyInvoice } from "src/components/footprint/SurveyInvoice";
import DrivewayDoc from "src/components/documents/DrivewayDoc";
import PVPerformanceDocs from "src/components/documents/pv-performance/PVPerformanceDocs";
import ASHPDoc from "src/components/documents/ashp/ASHPDoc";

import { routes } from "../constants";
import { NavBar } from "../components/navigation/NavBar";
import { Footer } from "../components/footer/Footer";
import { PrivateRoute } from "../components/navigation/PrivateRoute";
import { usePropertyEffect } from "../hooks/usePropertyEffect";
// pages
import { LoadingPage } from "../components/shared/LoadingPage"; // not lazy-loading as its used as fallback for lazy-loaded pages
import {
  RegisterPage,
  OAuthPage
} from "./login";
import HomePage from "./home/HomePage";
import FootprintPage from "./footprint/FootprintPage";
import FootprintAnonPage from "./footprint/FootprintAnonPage";
import { SectionGroup } from "./section/SectionGroup";
import PipedrivePage from "./pipedrive";
import ForgotPasswordPage from "./login/ForgotPassword";
import ResetPasswordPage from "./login/ResetPassword";
import { BSAEventRegister } from "./bsaEventRegister";
import { Polypipe } from "./polypipe";

const OFFSETTING_DISABLED = process.env.REACT_APP_OFFSETTING_DISABLED === "true";
// routes we'd like to lazy load:
// Note: React.lazy only supports default exports
// - therefore importing from a file which re-exports `*` from another module wont work either
const LandingPage = React.lazy(() => import("./landing/LandingPage"));
const AdminHome = React.lazy(() => import("./admin/AdminHome"));
const ErrorPage = React.lazy(() => import("./error/ErrorPage"));
// const ProfilePage = React.lazy(() => import("./profile/ProfilePage"));
const ImpactReviewWelcomePage = React.lazy(() => import("./section/ImpactReviewWelcomePage"));
const RetrievePage = React.lazy(() => import("./login/RetrievePage"));
const RetrieveCodePage = React.lazy(() => import("./login/RetrieveCodePage"));
const AboutPage = React.lazy(() => import("./about/AboutPage"));
const AboutGenousPage = React.lazy(() => import("./about-genous/AboutPage"));
const InsightsPage = React.lazy(() => import("./insights/AboutPage"));
const ReducePage = React.lazy(() => import("./reduce/ReducePage"));
const CaseStudiesPage = React.lazy(() => import("./case-studies/CaseStudiesPage"));
const UsefulInformationPage = React.lazy(() => import("./useful-information/UsefulInformationPage"));
const BlogPage = React.lazy(() => import("./blog/BlogPage"));
const FaqsPage = React.lazy(() => import("./faqs/FaqsPage"));
const ContactPage = React.lazy(() => import("./contact/ContactPage"));
const PrivacyPolicyPage = React.lazy(() => import("./privacyPolicy"));
const ChangePasswordPage = React.lazy(() => import("./change-password/ChangePasswordPage"));
const ChangeEmailPage = React.lazy(() => import("./change-email/ChangeEmailPage"));
const TakeAction = React.lazy(() => import("./footprint/subpages/TakeAction"));
const ScheduleCallConfirmed = React.lazy(() => import("./footprint/subpages/OffsetsIntro"));
const SuggestSignUpPage = React.lazy(() => import("./section/SuggestSignUpPage"));
const TermsAndConditionsPage = React.lazy(() => import("./termsAndConditions"));
const PortfolioChoice = React.lazy(() => import("./offsets/PortfolioChoice"));
const Confirm = React.lazy(() => import("./offsets/Confirm"));
const SetupOffsetPlan = React.lazy(() => import("./offsets/SetupOffsetPlan"));
const ChangePlan = React.lazy(() => import("./offsets/ChangePlan"));
const V2Page = React.lazy(() => import("./v2"));
const DrivewayPage = React.lazy(() => import("./driveway"));

function App(): JSX.Element {
  const { pathname } = useLocation();
  const { authDataState } = useAuthContext();
  const history = useHistory();

  useUtmParameters();
  // listen for page changes during questionnaire - and update propertyId
  // context where necessary
  usePropertyEffect();

  useEffect(() => {
    if (isStage("production")) {
      // Google Analytics
      ReactGA.initialize("G-6ELWZ2G7BN");

      // Facebook
      FacebookPixel.init("482531886986785", undefined, {
        autoConfig: true,
        debug: false
      });
      FacebookPixel.fbq("track", "PageView");
      // LinkedIn
      LinkedInTag.init("4105130", "dc", false);
      // Hotjar
      hotjar.initialize(2770532, 6);
      // Mixpanel
      mixpanel.init("7116c99725b0f58b179310fc3478aa7e");
      // Google Tag Manager
      TagManager.initialize({ gtmId: "GTM-NZ276T8" });
    } else {
      // Mixpanel
      mixpanel.init("856e0a075e858f012111cc0d475e06cd", { debug: true });
    }
  }, []);

  const handleDeclineCookies = () => {
    // Google Analytics
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    // Facebook
    FacebookPixel.revokeConsent();
    // LinkedIn
    LinkedInTag.disabled = true;
    // Hotjar?
    // Mixpanel
    mixpanel.opt_out_tracking();
  };

  React.useEffect(() => {
    if (isStage("production")) {
      ReactGA.send({
        hitType: "pageview",
        page: pathname
      });
    }

    mixpanel.track("page_view", { path: pathname });
  }, [ pathname ]);

  const [ cookieConsentHeight, setCookieConsentHeight ] = useState(0);
  const cookieConsentInner = useRef<HTMLDivElement>(null);
  const chatbotBottomMargin = 28;

  useEffect(() => {
    if (cookieConsentInner.current) {
      setCookieConsentHeight(cookieConsentInner.current?.parentElement?.parentElement?.clientHeight ?? 0);
    }
  }, [ cookieConsentInner.current?.parentElement?.parentElement?.clientHeight ]);

  const [ navigated, setNavigated ] = useState(false);

  history.listen(() => {
    setNavigated(true);
  });

  if (history.location.pathname === "/polypipe") {
    return <Polypipe />;
  }

  return (
    <>

      <NavBar />

      <main>

        {!navigated && !authDataState.authenticated && (
          <CookieConsent
            style={{
              background: theme.colors.white,
              color: theme.colors.black
            }}
            buttonStyle={{ background: theme.colors.green[ 100 ] }}
            enableDeclineButton
            declineButtonStyle={{ background: theme.colors.black }}
            onDecline={handleDeclineCookies}

          >

            {/* Shift Pipedrive chatbot above cookie banner when visible */}

            <style dangerouslySetInnerHTML={{ __html: `html body iframe#LeadboosterContainer { bottom: ${cookieConsentHeight + chatbotBottomMargin}px !important }` }} />

            <div ref={cookieConsentInner}>

              <p>
                Genous uses cookies to enhance the user experience. By using the site you accept this, or you can decline here (see also our <Link to={routes.privacyPolicy}>privacy policy</Link>).
              </p>
            </div>
          </CookieConsent>
        )}

        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route
              path={routes.landing + "/:landingPage?"}
              component={LandingPage}
            />

            <Route
              path={routes.footprintAnon}
              exact
              component={FootprintAnonPage}
            />

            <Route
              path={routes.register}
              exact
              component={RegisterPage}
            />

            <Route
              path={routes.bsaEvent}
              exact
              component={BSAEventRegister}
            />

            <Route
              path={routes.retrieve}
              exact
              component={RetrievePage}
            />

            <Route
              path={routes.retrieveCode}
              exact
              component={RetrieveCodePage}
            />

            <Route
              path={routes.forgotPassword}
              exact
              component={ForgotPasswordPage}
            />

            <Route
              path={routes.resetPassword}
              exact
              component={ResetPasswordPage}
            />

            <Route
              path={routes.oauth}
              exact
              component={OAuthPage}
            />

            <Route
              path={routes.aboutGenous + "/:aboutSection?"}
              exact
              component={AboutGenousPage}
            />

            <Route
              path={routes.about + "/:aboutSection?"}
              exact
              component={AboutPage}
            />

            <Route
              path={routes.insights + "/:aboutSection?"}
              exact
              component={InsightsPage}
            />

            <Route
              path={routes.reduce + "/:reduceSection?"}
              component={ReducePage}
            />

            {/* Legacy redirect */}

            <Route
              path="/reduce/:reduceSection?"
              render={props => (
                <Redirect
                  to={{ pathname: routes.reduce + (props.match.params.reduceSection ? "/" + props.match.params.reduceSection : "") }}
                />
              )}
            />

            <Route
              path={routes.caseStudies + "/:caseStudiesSection?"}
              component={CaseStudiesPage}
            />

            <Route
              path={routes.usefulInformation + "/:usefulInformationSection?"}
              component={UsefulInformationPage}
            />

            <Route
              path={routes.blog + "/:blogSection?"}
              component={BlogPage}
            />

            <Route
              path={routes.faqs}
              exact
              component={FaqsPage}
            />

            <Route
              path={routes.termsAndConditions}
              exact
              component={TermsAndConditionsPage}
            />

            <Route
              path={routes.privacyPolicy}
              exact
              component={PrivacyPolicyPage}
            />

            <Route
              path={routes.contact}
              exact
              component={ContactPage}
            />

            <Route
              path={routes.welcome}
              exact
              component={ImpactReviewWelcomePage}
            />

            <Route
              path={routes.suggestSignUp}
              exact
              component={SuggestSignUpPage}
            />

            <Route
              path={routes.section(":sectionId")}
              exact
              component={SectionGroup}
            />

            <Route
              path={routes.genousDoc(":driveway")}
              exact
              component={DrivewayDoc}
            />

            <Route
              path={routes.docsPVPerformance}
              exact
              component={PVPerformanceDocs}
            />

            <Route
              path={routes.docsASHP}
              exact
              component={ASHPDoc}
            />

            <PrivateRoute
              path={routes.admin}
              exact
              component={AdminHome}
            />

            <PrivateRoute
              path={routes.surveyInvoice}
              exact
              component={SurveyInvoice}
            />

            <PrivateRoute
              path={routes.footprintNextSteps}
              exact
              component={NextSteps}
            />

            <PrivateRoute
              path={routes.purchaseSurvey}
              exact
              component={SurveyPurchaseForm}
            />

            {/* <PrivateRoute
              path={routes.profile}
              exact
              component={ProfilePage}
            /> */}

            <PrivateRoute
              path={routes.changePassword}
              exact
              component={ChangePasswordPage}
            />

            <PrivateRoute
              path={routes.changeEmail}
              exact
              component={ChangeEmailPage}
            />

            <PrivateRoute
              path={routes.footprint}
              exact
              component={FootprintPage}
            />

            <PrivateRoute
              path={routes.takeAction}
              exact
              component={TakeAction}
            />

            <PrivateRoute
              path={routes.scheduleCallConfirmed}
              exact
              component={ScheduleCallConfirmed}
            />

            <PrivateRoute
              path={routes.offsets}
              exact
              component={PortfolioChoice}
              disabled={OFFSETTING_DISABLED}
            />

            <PrivateRoute
              path={routes.setupPlan}
              exact
              component={SetupOffsetPlan}
              disabled={OFFSETTING_DISABLED}
            />

            <PrivateRoute
              path={routes.confirmPlan}
              exact
              component={Confirm}
              disabled={OFFSETTING_DISABLED}
            />

            <PrivateRoute
              path={routes.managePlan}
              exact
              component={() => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                location.href = process.env.REACT_APP_STRIPE_PORTAL_URL!;

                return null;
              }}
              disabled={OFFSETTING_DISABLED}
            />

            <PrivateRoute
              path={routes.changePlan}
              exact
              component={ChangePlan}
              disabled={OFFSETTING_DISABLED}
            />

            <PrivateRoute
              path={routes.v2}
              exact
              component={V2Page}
            />

            <Route
              path="/error"
              exact
              component={ErrorPage}
            />

            <Route
              path={routes.home}
              exact
              component={HomePage}
            />

            <Route
              path={routes.pipedrive}
              exact
              component={PipedrivePage}
            />

            <Route
              path={routes.driveway}
              exact
              component={DrivewayPage}
            />
          </Switch>
        </Suspense>
      </main>

      <Footer />
    </>
  );
}
export default App;
