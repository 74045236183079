import {
  useRef,
  useEffect
} from "react";

/**
 * Returns a value as it was on the previous render, or as it was before _its_
 * (the values) change triggered a re-render. (i.e. state/prop)
 * @example
 * ```jsx
const [state, set] = useState(0);
const prev = usePrevious(state);
// ...
set(1);
// ...(re-render occurs)
// state => 1
// prev => 0
 * ```
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [ value ]);

  return ref.current;
}