import React, {
  useEffect,
  useState,
  useMemo
} from "react";
import { useDispatch } from "react-redux";
import { useQuestionsApi } from "src/api/questions/questions.api";
import { FullSpace } from "src/components/shared/FullSpace";
import { LoadingPage } from "src/components/shared/LoadingPage";
import theme from "src/components/theme";
import {
  updateCurrentSectionGroup,
  updateSectionGroups
} from "src/redux/actionCreators";
import { useTypedSelector } from "src/redux/useTypeSelector";
import ReactGA from "react-ga4";
import {
  Redirect,
  useLocation
} from "react-router";
import { isStage } from "src/helpers/stage";

import { routes } from "src/constants";

import { SectionGroupQuestions } from "./SectionGroupQuestions";
import { SectionGroupQuestionsWrapper } from "./SectionGroupQuestionsWrapper";
import { SectionProgressFrame } from "./SectionProgressFrame";
import SuggestSignUpPage from "./SuggestSignUpPage";

export const SectionGroup: React.FC = () => {
  // User & property data
  const { sectionGroups, currentSectionGroupIndex } = useTypedSelector(state => state.sectionGroups);
  // API calls
  const [ getNextUnansweredGroupResponse, getNextUnansweredGroupRequest ] = useQuestionsApi("GET_NEXT_UNANSWERED_IN_SECTION");
  const [ questionsFinished, setQuestionsFinished ] = useState<boolean>(false);

  // Section group data
  const totalStepsInSection = useMemo(() => {
    const total = getNextUnansweredGroupResponse?.data?.data?.sectionGroups?.length;

    return Number.isNaN(total) ? 0 : Math.max(total ? total - 1 : 1, 1) ?? 9;
  }, [ getNextUnansweredGroupResponse ]);

  const dispatch = useDispatch();
  const location = useLocation();

  // Get the next unanswered section group the first time you hit the form
  // After this first time the new section groups will be returned in the answers response
  // Basically, this will just get the first section group of the section
  useEffect(() => {
    getNextUnansweredGroupRequest({ id: 1 });
  }, [ getNextUnansweredGroupRequest ]);

  // Update the redux state when there's a next section group response
  useEffect(() => {
    if (getNextUnansweredGroupResponse && getNextUnansweredGroupResponse.data) {
      // Update the array of section groups
      dispatch(updateSectionGroups(getNextUnansweredGroupResponse.data.data.sectionGroups));
      // Update the index of the current section group
      dispatch(updateCurrentSectionGroup(getNextUnansweredGroupResponse.data.data.currentSectionGroup));
    }
  }, [ dispatch, getNextUnansweredGroupResponse ]);

  // Fire a page view event to Google Analytics when the page changes
  // The page doesn't re-render, which is why we need to do this in code
  // Only do this in production! We don't want to track page views on the staging and local sites!
  useEffect(() => {
    if (isStage("production") && typeof currentSectionGroupIndex === "number" && sectionGroups[ currentSectionGroupIndex ]) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + "/group/" + sectionGroups[ currentSectionGroupIndex ].id
      });
    }
  }, [
    location.pathname,
    currentSectionGroupIndex,
    sectionGroups
  ]);

  return (
    <FullSpace
      bg={theme.colors.gray[ 100 ]}
    >
      <SectionProgressFrame
        progress={{
          stepNumber: typeof currentSectionGroupIndex === "number" ? currentSectionGroupIndex : Infinity, // fallback to activating all section-step indicators
          totalStepsInSection
        }}
      />

      <SectionGroupQuestionsWrapper>
        {getNextUnansweredGroupResponse.pending ? (
          <LoadingPage />
        ) : sectionGroups.length && (
          (!questionsFinished ? (
            <SectionGroupQuestions
              onComplete={() => {
                setQuestionsFinished(true);
              }}
            />
          ) : (
            <Redirect to={routes.footprintAnon} />
          )))}
      </SectionGroupQuestionsWrapper>

    </FullSpace>
  );
};