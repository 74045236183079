import React from "react";

interface ProgressProviderProps {
  valueStart: number;
  valueEnd: number;
  children: (value: number) => JSX.Element;
}

export const ProgressProvider: React.FC<ProgressProviderProps> = ({
  valueStart, valueEnd, children
}) => {
  const [ value, setValue ] = React.useState(valueStart);

  React.useEffect(() => {
    setValue(valueEnd);
  }, [ valueEnd ]);

  if (!children) return null;

  return children(value);
};

export default ProgressProvider;