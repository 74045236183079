import React, {
  createContext,
  useCallback,
  useEffect,
  useState
} from "react";
import {
  Control,
  UseFormRegister,
  FieldValues,
  UseFormReset,
  UseFormGetValues
} from "react-hook-form";
import { Box } from "@chakra-ui/react";

import {
  Question as QuestionProp,
  QuestionType
} from "../../api/questions";
import {
  Input,
  Select,
  CheckboxGroup,
  Radio,
  AddressLookup,
  NumberInput,
  EPCLookup
} from ".";
import Map from "./Map";
import { PropertySize } from "./PropertySize";

type QuestionProps = {
  question: QuestionProp;
  control: Control;
  register: UseFormRegister<FieldValues>;
  setSkipButtonVisible: (boolean: true) => void;
  handleSubmit: any;
  reset: UseFormReset<FieldValues>;
  setValue: (name: string, value: unknown, config?: Record<string, unknown>) => void,
  getValues: UseFormGetValues<FieldValues>,
  // Part(s) of the questionnaire are multi-part format, therefore the Next btn
  // is not always necessary
  setNextButtonVisible?: React.Dispatch<React.SetStateAction<boolean>>
  index: number
};

let postcode = "";

/**
 * Gets the correct question component for a given question
 * @param param0 question - the question (from the API) to be presented to the user; control, register - form controls used by react-hook-form
 * @returns UI of a single question, with the correct input type based on the question type
 */
const UnstyledQuestion: React.FC<QuestionProps> = ({
  question, control, register, handleSubmit, setValue, getValues, setNextButtonVisible, setSkipButtonVisible, index, reset
}) => {
  const isEPCAnswered = !!question.answers?.[ 0 ];

  const setPC = useCallback(newPC => {
    postcode = newPC;
  }, []);

  return (
    <QuestionIndexContext.Provider value={index}>
      <QuestionEPCAnswerContext.Provider value={isEPCAnswered}>
        {(() => {
          switch (question.type) {
            case QuestionType.PINLocation:
              setSkipButtonVisible(true);

              return (
                <Map
                  question={question}
                  handleSubmit={handleSubmit}
                  key={question.id}
                  register={register}
                  setValue={setValue}
                  setNextButtonVisible={setNextButtonVisible}
                  setSkipButtonVisible={setSkipButtonVisible}
                  getValues={getValues}
                />
              );

            case QuestionType.Text:
              return (
                <Input
                  question={question}
                  key={question.id}
                  register={register}
                />
              );
            case QuestionType.Number: {
              return (
                <NumberInput
                  question={question}
                  key={question.id}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                />
              );
            }
            case QuestionType.Select: {
              const suppliedAnswer = question.answers?.[ 0 ];

              return (
                <Select
                  question={question}
                  control={control}
                  key={question.id}
                >
                  {question.questionOptions.map(option => {
                    return (
                      <option
                        // default-select to EPC/User supplied value where possible
                        selected={[ option.value, option.label ].includes(suppliedAnswer?.value.value)}
                        value={option.label || option.value}
                        key={option.id}
                      >
                        {option.value}
                      </option>
                    );
                  })}
                </Select>
              );
            }
            case QuestionType.Checkbox: {
              return (
                <CheckboxGroup
                  question={question}
                  register={register}
                  key={question.id}
                />
              );
            }
            case QuestionType.Radio: {
              return (
                <Radio
                  question={question}
                  control={control}
                  key={question.id}
                  setValue={setValue}
                />
              );
            }
            case QuestionType.AddressLookup: {
              return (
                <AddressLookup
                  question={question}
                  setPostcode={setPC}
                  control={control}
                  getValues={getValues}
                  key={question.id}
                  setNextButtonVisible={setNextButtonVisible}
                  reset={reset}
                />
              );
            }
            case QuestionType.EPCLookup: {
              return (
                <EPCLookup
                  question={question}
                  control={control}
                  postcode={postcode}
                  setValue={setValue}
                  setNextButtonVisible={setNextButtonVisible}
                  key={question.id}
                />
              );
            }

            case QuestionType.PropertySize: {
              return (
                <PropertySize
                  questionId={`${question.id}`}
                  setValue={setValue}
                />
              );
            }
            default: return <></>;
          }
        })()}

      </QuestionEPCAnswerContext.Provider>

    </QuestionIndexContext.Provider>
  );
};

export const QuestionIndexContext = createContext(-1);
export const QuestionEPCAnswerContext = createContext(false);

export const Question = UnstyledQuestion;