import {
  useRadioGroup,
  HStack,
  Box
} from "@chakra-ui/react";

import { RadioCard } from "../form/RadioCard";
import theme from "../theme";

interface RadioSwitchProps extends ReturnType<typeof useRadioGroup> {
  options: Array<string | ({value: string} & Record<string, unknown>)>,
  children: NonNullable<Parameters<typeof RadioCard>[0]["render"]>
}

/**
 * Passing in the returned obj from an initialised `useRadioGroup` hook (from Chakra)
 * @example
 * const { getRootProps, getRadioProps, value } = useRadioGroup({...})
 * // somewhere further down...
 * <RadioSwitch chakraRootProps={getRootProps} chakraRadioProps={getRadioProps}() {...}>
 *
 * ...and render each as though mapped using the a render fn, thats passed its respective radio props (emulates render prop from RadioCard) e.g.
 * @see {@link RadioCard}
 * @example
 * <RadioSwitch {...}>
 *  {({value, checked, ...etc}) => <Text>{value}</Text>}
 * </RadioSwitch>
 */
const RadioSwitch: React.FC<RadioSwitchProps> = ({
  options, getRootProps, getRadioProps, children
}) => {
  return (
    <HStack
      {...getRootProps}
      bg={theme.colors.gray[ "200" ]}
      p={theme.spacing[ 1 ]}
      borderRadius={`calc(${theme.radius.lg} + (${theme.spacing[ 1 ]} / 2))`}
    >
      {options.map(option => {
        const value = typeof option === "string" ? option : "value" in option ? option.value : "";
        const radio = getRadioProps({ value });

        return (
          <RadioCard
            key={value}
            radioCheckboxProps={{ cursor: "pointer" }}
            {...radio}
            render={({ checked, ...rest }) => (
              <Box
                bg={checked ? "white" : "none"}
                borderRadius={theme.radius.lg}
                overflow="hidden"
                py={theme.spacing[ 2 ]}
                textAlign="center"
                textTransform="capitalize"
                userSelect="none"
                children={children({
                  checked,
                  ...rest
                })}
              />
            )}
          />
        );
      })}
    </HStack>
  );
};

export default RadioSwitch;