import { remove } from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { updatePropertyId } from "src/redux/actionCreators";
import { useTypedSelector } from "src/redux/useTypeSelector";

import { routes } from "../constants";
import { usePrevious } from "./usePrevious";

// easier to debug in dev - doesn't reset form on reload
const __avoidPropertyIdReset = process.env.REACT_APP_NO_FORM_RESET === "true";

/**
 * Held property data can mess up experience if user navigates away during form.
 * This hook will clear the held property data if that should happen.
*/
export function usePropertyEffect(): void {
  const history = useHistory();
  const { propertyId } = useTypedSelector(state => state.property);
  const dispatch = useDispatch();
  // keep previous property state around so we can compare, and know when its
  // been cleared (i.e. was a non-nullable value on one render; but on the next render is _not_)
  const prevPropertyId = usePrevious(propertyId);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const isQuestionSectionPage = getIsQuestionSectionPage(location.pathname);
      const isFootprintAnonPage = getIsFootprintAnonPage(location.pathname);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isRegisterAfterAnonDashboard = location.pathname === routes.register && getIsFootprintAnonPage(((location.state as any)?.from) ?? "");
      const wentBackDuringForm = action === "POP" && isQuestionSectionPage;
      // const exitedForm = !!propertyId && !isQuestionSectionPage && !isRegisterAfterQuestions;
      const exitedHomeCheck = !!propertyId && !isFootprintAnonPage && !isRegisterAfterAnonDashboard;

      if (wentBackDuringForm || exitedHomeCheck) {
        // clear form progress & saved data
        if (!__avoidPropertyIdReset) {
          dispatch(updatePropertyId(undefined));
        }
      }

      if (history.action !== "POP") {
        window.scrollTo(0, 0);
      }
    });

    return () => unlisten();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ propertyId ]);

  useEffect(() => {
    const isPropertyIdReset = !propertyId && prevPropertyId;

    if (isPropertyIdReset && getIsQuestionSectionPage(history.location.pathname)) {
      // if navigating _back_ during form, send back to beginning
      if (!__avoidPropertyIdReset) {
        history.replace(routes.welcome);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ prevPropertyId, propertyId ]);
}

const getIsQuestionSectionPage = (pathname: string) => pathname.startsWith(routes.section(1).replace(/\/\d+$/, ""));
const getIsFootprintAnonPage = (pathname: string) => pathname === routes.footprintAnon;
