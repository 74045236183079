import React, {
  useEffect,
  useState
} from "react";
import { useHistory } from "react-router";
import {
  Box,
  VStack,
  HStack,
  Checkbox
} from "@chakra-ui/react";
import FacebookPixel from "react-facebook-pixel";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import Auth from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import { useTypedSelector } from "src/redux/useTypeSelector";
import { flushSync } from "react-dom";
import { generateRandomPassword } from "src/helpers/password";

import { getUKTelephoneSchema } from "src/helpers";

import { useAuthContext } from "../../hooks/useAuthContext";
import { LoginPageProps } from ".";
import { StyledChakraTextInput as Input } from "../../components/form/Input";
import { Button } from "../../components/button";
import { CenterContent } from "../../components/shared/CenterContent";
import { Text } from "../../components/text";
import { FieldError } from "../../components/form";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { Wrapper } from "../../components/shared/Wrapper";
import { FullSpace } from "../../components/shared/FullSpace";
import theme from "../../components/theme";
import { routes } from "../../constants";
import { useQuestionsApi } from "../../api/questions/questions.api";
import { SectionProgressFrame } from "../section/SectionProgressFrame";

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string()
    .pattern(new RegExp("^[a-zA-Z0-9]{8,}$")).required().messages({ "string.pattern.base": "Password must be at least 8 characters and contain only letters and numbers." }),
  confirm_password: Joi.string()
    .valid(Joi.ref("password"))
    .required()
    .messages({ "any.only": "Passwords do not match" }),
  telephone: getUKTelephoneSchema().empty("").optional(),
  termsAndConditions: Joi.boolean().required().valid(true)
});

/**
 * NOTE:
 * Make sure if you are pushing to the RegisterPage from within the questions
 * sections - you push location with state.from, this tells the page that you
 * are correctly navigating to the RegisterPage from within the questions and
 * wont redirect you to the beginning of the questions!
 */
export const RegisterPage: React.FC<LoginPageProps> = () => {
  const { authDataState, setAuthDataState } = useAuthContext();
  const { propertyId } = useTypedSelector(state => state.property);
  const history = useHistory();
  const [ createUserError, setCreateUserError ] = useState<string | null>(null);

  const [ getNextUnansweredGroupResponse, getNextUnansweredGroupRequest ] =
    useQuestionsApi("GET_NEXT_UNANSWERED_IN_SECTION");

  const isPostAnswers = !!propertyId;

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({ resolver: joiResolver(schema) });

  const handleRegister = async (data: Record<string, string>) => {
    try {
      // Set authenticating to true whilst we try and create the user
      setAuthDataState({
        authenticated: false,
        error: null,
        authenticating: true,
        idToken: "",
        isAdmin: false
      });

      // const password = generateRandomPassword();

      await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          "custom:firstName": data.firstName,
          "custom:lastName": data.lastName,
          "custom:termsAndConditions": "true",
          "custom:propertyId": String(propertyId),
          ...(data.telephone ? { "custom:telephone": data.telephone } : {})
        }
      });
      FacebookPixel.fbq("track", "Lead");

      const response = await Auth.signIn(data.email, data.password);

      flushSync(() =>
        setAuthDataState({
          authenticated: true,
          error: null,
          authenticating: false,
          idToken: response.signInUserSession?.idToken?.jwtToken || "",
          isAdmin: false
        })
      );
      history.push(routes.footprint);
    } catch (e) {
      setAuthDataState({
        authenticated: false,
        error: String(e),
        authenticating: false,
        idToken: "",
        isAdmin: false
      });
      setCreateUserError(String(e));
    }
  };

  useEffect(() => {
    if (isPostAnswers) {
      getNextUnansweredGroupRequest({
        id: 1,
        propertyId
      });
    }
  }, [
    getNextUnansweredGroupRequest,
    isPostAnswers,
    propertyId
  ]);

  useEffect(() => {
    // See comment at top of file regarding redirecting if
    // you are getting incorrectly redirected away
    //
    // Don't allow a user to register if they haven't completed the questionnaire
    // This will be if there's no propertyId in local state
    // Or if the get next question section in group response is not an empty array
    if (!isPostAnswers) {
      history.replace(routes.welcome);
    } else if (
      getNextUnansweredGroupResponse.complete &&
      getNextUnansweredGroupResponse.data &&
      typeof getNextUnansweredGroupResponse.data.data.currentSectionGroup ===
        "number"
    ) {
      history.replace(routes.section(1));
    }
  }, [
    propertyId,
    getNextUnansweredGroupResponse,
    history,
    isPostAnswers
  ]);

  return (
    <FullSpace
      bg={theme.colors.gray[ 100 ]}
      py={isPostAnswers ? 0 : theme.spacing[ 8 ]}
    >
      {isPostAnswers && (
        <SectionProgressFrame
          progress={{
            stepNumber: 11,
            totalStepsInSection: 11 // this is simply a placeholder as we cannot know ahead of time, the change in totalSteps shouldn't be noticeable, but if it is, we can change this number accordingly
          }}
        />
      )}

      <Wrapper>
        <CenterContent
          justifyContent="flex-start"

        >
          {authDataState.authenticating ? (
            <LoadingPage />
          ) : (
            <Box
              my={theme.spacing[ 10 ]}
              maxWidth={600}
            >
              {!!propertyId && (
                <Text align="center">
                  Please register to view your <b>detailed results</b> and
                  recommendations.

                  <br />

                  <br />

                  We keep your data{" "}

                  <Text
                    as={Link}
                    to={routes.privacyPolicy}
                    textDecoration="underline"
                    target="_blank"
                  >
                    private
                  </Text>{" "}

                  {" "}

                  and only use your details to contact you about your
                  recommendations.
                </Text>
              )}

              <form
                onSubmit={handleSubmit(handleRegister)}
                style={{ width: "100%" }}
              >
                <VStack
                  marginTop="2rem"
                  spacing={4}
                  width="100%"
                >
                  {createUserError && (
                    <Text color="red">{createUserError}</Text>
                  )}

                  <HStack w="100%">
                    <Input
                      type="text"
                      placeholder="First name"
                      isInvalid={errors.firstName}
                      {...register("firstName")}
                    />

                    <Input
                      type="text"
                      placeholder="Last name"
                      isInvalid={errors.lastName}
                      {...register("lastName")}
                    />
                  </HStack>

                  <FieldError
                    error={errors.firstName}
                    fieldName="First name"
                  />

                  <FieldError
                    error={errors.lastName}
                    fieldName="Last name"
                  />

                  <Input
                    type="email"
                    placeholder="Email address"
                    isInvalid={errors.email}
                    {...register("email")}
                  />

                  <FieldError
                    error={errors.email}
                    fieldName="Email address"
                  />

                  <Input
                    type="password"
                    placeholder="Password"
                    isInvalid={errors.password}
                    {...register("password")}
                  />

                  <FieldError
                    error={errors.password}
                    fieldName="Password"
                  />

                  <Input
                    type="password"
                    placeholder="Confirm Password"
                    isInvalid={errors.confirm_password}
                    {...register("confirm_password")}
                  />

                  <FieldError
                    error={errors.confirm_password}
                    fieldName="Confirm Password"
                  />

                  <Input
                    type="tel"
                    placeholder="Phone number (optional)"
                    isInvalid={errors.telephone}
                    {...register("telephone")}
                  />

                  <FieldError
                    error={errors.telephone}
                    fieldName="Phone number"
                  />

                  <br />

                  <Checkbox
                    isInvalid={errors.termsAndConditions}
                    {...register("termsAndConditions")}
                  >
                    I accept the Genous{" "}

                    <Text
                      as={Link}
                      to={routes.termsAndConditions}
                      textDecoration="underline"
                      target="_blank"
                    >
                      terms and conditions
                    </Text>
                  </Checkbox>

                  <FieldError
                    error={errors.termsAndConditions}
                    fieldName="Terms and conditions"
                  />

                  <Button
                    type="submit"
                    disabled={!isDirty}
                    mt={`${theme.spacing[ 10 ]} !important`}
                    className="registerBtn"
                  >
                    Register
                  </Button>
                </VStack>
              </form>
            </Box>
          )}
        </CenterContent>
      </Wrapper>
    </FullSpace>
  );
};
